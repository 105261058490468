<template>
  <div class="review" :class="{skeleton: !state.loaded}">
    <div class="inner">
      <div class="title">
        <span>후기 작성</span>
      </div>
      <div class="rewards" v-if="modalParams.list">
        <div class="sub-title">
          <span>후기를 작성할 리워드를 골라주세요!</span>
        </div>
        <select class="form-control list" v-model="state.args.investorSeq" @change="onRewardChange($event)">
          <option v-for="(r, idx) in modalParams.list" :value="r.investorSeq" :data-id="r.reviewId" :key="idx">{{ r.wroteReview === "Y" ? `${r.title} (작성완료)` : r.title }}</option>
        </select>
      </div>
      <div class="tags">
        <div class="sub-title">
          <span>리워드는 어떠셨나요?</span>
          <span class="desc">펀딩 후기를 키워드로 남겨주세요! &nbsp; (최대 5개)</span>
        </div>
        <div class="words">
          <div :class="{'selected': state.args.selectedTags.includes(n.name)}" class="word" v-for="(n, idx) in $definitions.keywords.reviews" :key="idx" @click="selectTags(n.name)">
            <span>{{ n.title }}</span>
          </div>
        </div>
      </div>
      <div class="text">
        <div class="sub-title clearfix">
          <span>리워드 후기를 작성해주세요</span>
          <span class="desc count float-right">{{ state.args.content.trim().length }} / 1000</span>
        </div>
        <textarea v-model="state.args.content" class="border-focus-point form-control" :placeholder="`최소 ${minContentSize}자 이상 작성해주세요.`" maxlength="1000" @keyup.enter.ctrl="submit()"></textarea>
      </div>
      <div class="img-upload">
        <div class="sub-title">
          <span>사진 첨부</span>
          <span class="desc">최대 5개까지 올릴 수 있습니다. &nbsp; (png 또는 jpg 형식)</span>
        </div>
        <div class="wrapper" @click.stop="clearInput($event)">
          <i class="fa fa-picture-o"></i>
          <span class="title">사진 첨부하기</span>
          <input type="file" :accept="$definitions.limits.fileExtensions.imgStr" class="file" @change="setFiles($event)" multiple/>
        </div>
        <div class="preview">
          <div v-for="(i, idx) in state.files" :key="idx">
            <span class="close" @click="deleteFile(i.file.name)"><img src="/assets/ico/modal.common.close.bold.svg" alt="닫기 표시"></span>
            <img :src="i.path" alt="img">
          </div>
        </div>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-default btn-block" @click="submit()">저장하기</button>
    </div>
  </div>
</template>
<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "modalReview";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
      resetState();

      state.args.projectSeq = modalParams.projectSeq;
      state.args.investorSeq = modalParams.investorSeq;
      state.id = modalParams.id;

      if (modalParams.edit) {
        loadEdit();
      } else if (modalParams.projects) {
        loadProjects();
      }
    });

    const state = reactive({
      args: {
        selectedTags: [],
        content: "",
        investorSeq: "",
        projectSeq: "",
        photos: [],
      },
      files: [],
      newFiles: [],
      id: 0,
      loaded: true,
    });

    const MIN_CONTENT_SIZE = 20;

    const modalParams = store.getters.modalParams(component);

    const loadEdit = () => {
      if (!state.id) {
        return;
      }

      state.loaded = false;

      http.get(`/api/project/review/${state.id}`).then(({data}) => {
        state.loaded = true;
        state.args.selectedTags = data.body.selectedTags.split(",");
        state.args.content = data.body.content;
        state.args.investorSeq = data.body.investorSeq;
        state.args.projectSeq = data.body.projectSeq;

        for (let i of data.body.photos) {
          state.files.push({
            path: `${i.filePath + i.fileSaveName}`,
            file: {
              ...i,
              name: i.fileSaveName
            }
          });
        }
      });
    };

    const loadProjects = () => {
      if (!modalParams.projects) {
        return;
      }

      state.args.investorSeq = modalParams.list[0].investorSeq;
      state.id = modalParams.list[0].reviewId;

      loadEdit();
    };

    const onRewardChange = (e) => {
      resetState();
      e.target.querySelectorAll("option").forEach(i => {
        if (Number(i.value) === Number(state.args.investorSeq) && i.dataset.id) {
          state.id = Number(i.dataset.id);
          return loadEdit();
        }
      });
    };

    const resetState = () => {
      state.args.selectedTags = [];
      state.args.content = "";
      state.args.photos = [];
      state.newFiles = [];
      state.files = [];
    };

    const setFiles = (e) => {
      const maxFileCnt = 5;
      const maxFileSize = 50000000;

      if (!store.getters.isAllowedExtension(e.target, "image")) {
        return;
      }

      if (e.target.files.length + state.files.length > maxFileCnt) {
        return store.commit("setSwingMessage", "사진은 최대 5개까지 올릴 수 있습니다.");
      }

      for (let i = 0; i < e.target.files.length; i += 1) {
        if (e.target.files[i].size > maxFileSize) {
          store.commit("setSwingMessage", `첨부 파일의 용량이 너무 큽니다. 50MB 이하로 올려주세요.`);
          continue;
        }

        const reader = new FileReader();
        reader.onload = function (ev) {
          state.newFiles.push({
            file: e.target.files[i],
            path: ev.target.result,
          });
          state.files.push({
            file: e.target.files[i],
            path: ev.target.result,
          });
        };
        reader.readAsDataURL(e.target.files[i]);
      }
    };

    const deleteFile = (name) => {
      for (let i in state.files) {
        if (state.files[i].file.name === name) {
          if (state.files[i].file.delFlag) {
            state.args.photos.push({
              id: state.files[i].file.id,
              delFlag: "Y"
            });
          } else {
            state.newFiles = state.newFiles.filter(f => f.file.name !== name);
          }

          state.files.splice(i, 1);
        }
      }
    };

    const clearInput = (e) => {
      e.currentTarget.querySelector("input").value = "";
    };

    const selectTags = (name) => {
      if (state.args.selectedTags.includes(name)) {
        return state.args.selectedTags.splice(state.args.selectedTags.indexOf(name), 1);
      }

      if (name === "none") {
        return state.args.selectedTags = ["none"];
      }

      if (state.args.selectedTags.includes("none")) {
        state.args.selectedTags.splice(state.args.selectedTags.indexOf("none"), 1);
      }

      if (state.args.selectedTags.length >= 5 && !state.args.selectedTags.includes(name)) {
        return store.commit("setSwingMessage", "최대 5개까지 선택 가능합니다.");
      }

      return state.args.selectedTags.push(name);
    };

    function repeated(content) {
      const input = content?.split(" ").join("").split("\n").join("").trim();

      const getMaxWordLen = () => {
        return Math.ceil(input.length / maxRepeatedCnt);
      };

      let maxRepeatedCnt = 5;
      let maxWordLen = getMaxWordLen();

      for (let wordLen = 1; wordLen <= maxWordLen; wordLen++) {
        let currWord = "";
        let repeatedCnt = 1;
        let startIdx = 0;
        let startIdxWhenDiff = 0;
        let limit = 10000;

        if (wordLen > 3) {
          maxRepeatedCnt = 3;
          maxWordLen = getMaxWordLen();
        }

        while (limit-- > 0) {
          if (!currWord) {
            currWord = input.substring(startIdxWhenDiff, startIdxWhenDiff + wordLen);
            startIdx = wordLen;
            continue;
          }

          const currWordInLoop = input.substring(startIdx, startIdx + wordLen);

          if (currWord === currWordInLoop) {
            startIdx += wordLen;

            if (++repeatedCnt >= maxRepeatedCnt) {
              return true;
            }
          } else if (startIdx + wordLen === input.length) {
            break;
          } else {
            startIdxWhenDiff++;
            currWord = input.substring(startIdxWhenDiff, startIdxWhenDiff + wordLen);
            repeatedCnt = 1;
            startIdx = startIdxWhenDiff + wordLen;
          }
        }
      }

      return false;
    }

    const submit = () => {
      const content = state.args.content?.trim();

      if (state.args.selectedTags.length < 1) {
        return store.commit("setSwingMessage", "키워드를 하나 이상 선택해주세요.");
      }

      if (!content) {
        return store.commit("setSwingMessage", `후기 내용을 작성해주세요.`);
      }

      if (content.length < MIN_CONTENT_SIZE) {
        return store.commit("setSwingMessage", `후기 내용을 최소 ${MIN_CONTENT_SIZE}자 이상 작성해주세요.`);
      }

      if (repeated(content)) {
        return store.commit("setSwingMessage", "후기 내용 중 지속적으로 반복되는 단어가 있습니다.");
      }

      let args = {
        ...state.args,
        content: content,
        selectedTags: state.args.selectedTags.join(),
      };

      store.commit("confirm", {
        message: "후기를 등록하시겠습니까?",
        subMessage: "비방 및 욕설 등이 포함된 후기는 비공개로 전환될 수 있습니다.",
        allow() {
          if (state.id) {
            fileUpload(state.id);
            http.put(`/api/project/review/${state.id}`, args).then(() => {
              close();
            });
          } else {
            http.post("/api/project/review", args).then(({data}) => {
              fileUpload(data.body.id);
              close();
            });
          }
        }
      });
    };

    const fileUpload = (id) => {
      if (state.newFiles.length <= 0) {
        return;
      }

      const formData = new FormData();

      for (let i in state.newFiles) {
        formData.append("photos", state.newFiles[i].file);
      }

      http.post(`/api/project/review/${id}/upload`, formData);
    };

    const close = () => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
          store.commit("refresh");
          store.commit("setSwingMessage", "후기가 등록되었습니다.");
        }
      });
    };

    return {component, state, minContentSize: MIN_CONTENT_SIZE, modalParams, selectTags, submit, setFiles, deleteFile, onRewardChange, clearInput};
  }
});
</script>
<style lang="scss" scoped>
.review {
  background: #fff;
  font-size: $px14;

  > .inner {
    padding: $px20;

    > .rewards {
      select {
        font-size: $px12;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: $px40;
      }
    }

    > .title {
      font-size: $px18;
    }

    .tags {
      .words {
        margin-bottom: $px-10;

        .word {
          border: $px1 solid $colorBorder;
          border-radius: $px100;
          cursor: pointer;
          display: inline-block;
          font-size: $px12;
          padding: $px8 $px10;
          position: relative;
          margin-bottom: $px10;
          transition: 0.18s;

          > .act {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;

            &::after {
              width: 100%;
              height: 100%;
              background-color: $colorPointActive;
            }
          }

          &:not(:last-child) {
            margin-right: $px10;
          }

          &.selected {
            background-color: $colorPoint;
            border: 1px solid $colorPoint;
            color: #fff;
          }
        }
      }
    }

    .text {
      .sub-title {
        .count {
          display: inline-block;
          margin: 0;
        }
      }

      textarea {
        font-size: $px14;
        min-height: $px150;
        resize: none;
      }
    }

    .img-upload {
      margin-top: $px20;

      .wrapper {
        border: 1px solid $colorBorder;
        text-align: center;
        padding: $px20;
        position: relative;
        transition: background-color 0.18s;

        > i {
          margin-right: $px10;
        }

        > input {
          border-radius: $px4;
          cursor: pointer;
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:hover {
          background-color: $colorBackground;
        }
      }

      .preview {
        width: 100%;
        margin-top: $px10;

        > div {
          display: inline-block;
          width: calc((100% - $px40) / 5);
          height: $px70;
          position: relative;

          .close {
            background-color: #000;
            opacity: 0.85;
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: $px18;
            height: $px18;
            cursor: pointer;

            > img {
              width: 50%;
              height: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          > img {
            object-fit: cover;
            width: 100%;
            height: inherit;
          }

          &:not(:last-child) {
            margin-right: $px10;
          }
        }
      }
    }
  }

  .action {
    button {
      border: none;
      border-radius: 0;
      background-color: $colorPoint;
      color: #fff;
      padding: $px15 0;
      transition: background-color 0.18s;

      &:hover {
        background-color: $colorPointActive;
      }
    }
  }

  .sub-title {
    font-weight: 500;
    margin: $px40 0 $px10 0;
    line-height: 1;

    .desc {
      color: #aaa;
      display: block;
      font-size: $px12;
      font-weight: 400;
      margin: $px10 0;
    }
  }

  &.skeleton {
    .sub-title, .sub-title .desc {
      @include skeleton;
    }

    > .inner {
      > .title, .img-upload .wrapper {
        @include skeleton;
      }
    }

    .rewards {
      .list {
        @include skeleton;
      }
    }

    .tags .words .word {
      @include skeleton;
    }

    .text textarea {
      @include skeleton;
    }

    .action button {
      @include skeleton;
    }
  }
}
</style>